.genz__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding: 2rem 4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.genz__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 23, 23, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 3.5rem;
  max-width: 40%;
  border-radius: 35px;
}

.genz__navbar-links-logo img {
  max-width: 150px;
  height: 51px;
}
.genz__navbar-links-container {
  display: flex;
  flex-direction: row;
  position: sticky;
}
.genz__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.genz__navbar-links-container p,
.genz__navbar-sign p,
.genz__navbar-menu_container p {
  color: #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 0 2rem;
  cursor: pointer;
}

.genz__navbar-sign button,
.genz__navbar-menu_container button {
  padding: 1rem 1.5rem;
  color: #cccccc;
  background: #171717;
  font-family: var(--font-body);
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  outline: none;
  cursor: pointer;
  border-radius: 35px;
  border: none;
}

.genz__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.genz__navbar-menu svg {
  cursor: pointer;
}

.genz__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
  background: var(--color-footer);
  /* opacity: 80%; */
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;

  margin-top: 1rem;
  width: 215px;
  border-radius: 20px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  /* filter: blur(5px); */
}

.genz__navbar-menu_container p {
  margin: 1rem 0;
}

@media screen and (max-width: 1350px) {
  .genz__navbar {
    padding: 2rem 4rem;
  }
  .genz__navbar-links {
    display: none;
  }
  .genz__navbar-menu {
    display: flex;
  }
  .genz__navbar-sign {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .genz__navbar {
    padding: 2rem 2rem 0rem;
    background: rgba(23, 23, 23, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin-top: 0;
  }
  .genz__navbar-sign {
    display: none;
  }
  .genz__navbar-menu_container {
    top: 20px;
  }
}
div.scrolled {
  .genz__navbar-links {
    /* opacity: 0.9; */
    backdrop-filter: blur(10px);
  }
}
