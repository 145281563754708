.genz__footer_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
}
hr {
  color: #ffffff;
  /* height: 5; */
  width: 80%;
}
.genz__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 5rem;
  margin-bottom: 0;
}
.genz__footer_1 {
  align-content: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 5rem;
  margin-bottom: 0;
}

.genz__footer-links-logo img {
  max-width: 150px;
  height: 51px;
  /* opacity: 0.4; */
}
.genz__footer_1_links {
  display: flex;
  flex-direction: row;
}
.genz__footer_1_links div {
  padding: 0 1rem 0.5rem 1rem;
}
.genz__footer-links-container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.genz__footer_1 p,
.genz__footer-links-container p {
  color: #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  padding: 0.5rem;
  line-height: 130%;
  margin: 0 1rem;
  /* cursor: pointer; */
}
.genz__footer-links-container a {
  color: #ffffff;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  padding: 0.5rem;
  line-height: 130%;
  margin: 0 1rem;
  cursor: pointer;
}
@media screen and (max-width: 1050px) {
  .genz__footer {
    display: flex;
    flex-direction: column;
  }
  .genz__footer-links-container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 650px) {
  .genz__footer,
  .genz__footer_1 {
    padding: 2rem 0rem;
  }
  .genz__footer-links-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0rem;
    margin-top: 1rem;
  }
  .web {
    display: none;
  }
  .genz__footer-links-container p {
    padding-top: 1rem;
  }
}
@media screen and (min-width: 650px) {
  .mobile {
    display: none;
  }
}
