.genz__header {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
}
.genz__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  padding-top: 3rem;
  /* padding-bottom: 3rem; */

  /* Background Image */
  background-image: url(../../assets/Group.png);
  background-position: center;
  background-size: cover;

  /* Background Overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(13, 13, 13, 0.5);
  }
}
.genz__header-content_center {
  flex-direction: column;
  position: relative;
  z-index: 0.5;
}

.genz__header-content h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 80px;
  line-height: 115%;
  text-align: center;
  letter-spacing: -0.03em;
}
.genz__header-content p {
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--color-textbody);
  margin-top: 2rem;
  margin-left: 10rem;
  margin-right: 10rem;
}
.genz__header-content__input {
  width: 50%;
  margin: 3rem auto 1rem;
  display: flex;
}
.genz__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  background: var(--color-bg);
  border-radius: 25px 0 0 25px;
  border: 1px solid #7d7f78;
  padding: 0 1rem;
  outline: none;

  font-family: var(--font-family);
  font-weight: 400;
  color: var(--color-text);
  font-size: 16px;
  line-height: 130%;
}
.genz__header-content__input button {
  flex: 1;
  width: 100%;
  min-height: 50px;
  background: var(--color-subtext);
  border-radius: 0 25px 25px 0;
  border: 1px solid #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--color-bg);
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;
  box-shadow: rgba(221, 255, 0, 1) 0px 25px 50px -12px;
}

/* images */
.genz__header-content_image {
  flex: 1;
  display: flex;
  margin: auto 2rem 0rem;
  justify-content: center;
  align-items: baseline;
}

.genz__header-content img {
  /* position: relative; */
  width: 207px;
  height: 212px;
  border-radius: 25px;
  opacity: 0.5;

  /* align-items: baseline; */
}
.genz__header-images {
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: baseline;
  flex-direction: row;
  margin: auto 5rem 0rem;
  height: 50px;
  display: flex;
}
.genz__header-images img {
  margin: auto 5rem 0rem;
}
.genz__header-image1 img {
  width: 266px;
  height: 302px;
  border-radius: 25px;
  /* opacity: 0.5; */
}
.genz__header-image2 img {
  width: 280px;
  height: 194px;
  border-radius: 25px;
  /* opacity: 0.5; */
}
.genz__header-image3 img {
  width: 272px;
  height: 294px;
  border-radius: 25px;
  /* opacity: 0.5; */
}
@media screen and (max-width: 1450px) {
  .genz__header {
    flex-direction: column;
    padding: auto 10rem;
  }
  .genz__header-content {
    margin: 0 0 3rem;
  }
  .genz__header p {
    margin-left: auto;
    margin-right: auto;
  }
  .genz__header-content__input {
    width: 60%;
  }
  .genz__header-content_image {
    align-items: center;
    margin-bottom: 10rem;
    /* display: none; */
  }
  .genz__header-images {
    /* width: 100%; */
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
  }
}
@media screen and (min-width: 650px) {
  .header {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .genz__header-images {
    display: none;
  }
  .header {
    display: flex;
  }
  .genz__header {
    /* padding-top: 3rem; */
    margin-top: 3rem;
  }
  .genz__header h1 {
    font-size: 46px;
    line-height: 60px;
  }
  .genz__header p {
    font-size: 14px;
    line-height: 24px;
  }

  .genz__header-content_image {
    display: none;
  }
  .genz__header-content__input {
    width: 100%;
  }
  .genz__header-content__input input {
    font-size: 14px;
  }
  .genz__header-content__input button {
    font-weight: 400;
    font-size: 10px;
  }
  .genz__header-images {
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
    align-items: center;
  }
}
/* slider */

.header {
  overflow: hidden;
  padding: auto 0;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
}

.header:before,
.header:after {
  position: absolute;
  top: 0;
  max-width: 80%;
  height: 100%;
  content: "";
  z-index: 3;
}

.header:before {
  left: 0;
  opacity: 30%;
}

.header:after {
  right: 0;
  opacity: 30%;
}

.header-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

.header-slide img {
  max-height: 150px;
  max-width: 150px;
  margin: 0 1.5rem;
  border-radius: 15px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/*  */
.genz__header-content__input {
  position: relative;
  text-align: center;

}

.genz__header-content__input p {
  position: absolute;
  /* left: 50%; */
  bottom: -25px;
  /* left: 0; */
  color: var(--color-subtext);
  font-size: 14px;
}
