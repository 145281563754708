.genz__contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.genz__contacts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.genz__contacts-intro p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.genz__contacts-intro h2 {
  padding: 2rem;
  color: var(--color-textbody);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
}
.genz__contacts-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 80%; */
  /* min-width: 50%; */
  border-radius: 45px;
  background: linear-gradient(
    45deg,
    rgba(26, 26, 26, 1) 0%,
    rgba(13, 13, 13, 1) 100%
  );
}
.genz__contacts-form p {
  color: var(--color-text);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  padding: 2rem;
}
.genz__contacts-form form {
  margin-top: 2rem;
}
.genz__contacts-form_col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.genz__contacts-form_button button {
  padding: 1rem 1.5rem;
  color: #161616;
  background: #ffe700;
  margin-bottom: 2rem;
  font-family: var(--font-body);
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border-radius: 25px;
  border: 0;
  outline: none;
  cursor: pointer;
}
.genz__contacts-form_button {
  display: flex;
  flex-direction: column; 
  align-items: center;
  /* margin-top: 1rem;  */
}

/* learn */
.single_form {
  margin: 0 2rem 2rem;
}
.single_form input[type="text"],
.single_form input[type="email"],
.single_form input[type="tel"],
.single_form textarea {
  width: 100%;
  background: transparent;
  font-family: var(--font-body);
  height: 41px;
  border: none;
  border-bottom: 2px solid #292929;
  font-size: 14px;
  outline: none;
}
.single_form textarea {
  resize: none;
  height: 100px;
  margin-bottom: 20px;
}
input,
select,
textarea {
  color: var(--color-text);
}
textarea {
  padding: 1rem;
}
.single_form label {
  font-size: 14px;
  font-family: var(--font-body);
  /* text-transform: uppercase; */
  font-weight: 400;
  line-height: 0.8;
  color: var(--color-textbody);
  cursor: pointer;
}
@media screen and (max-width: 650px) {
  .genz__contacts-form {
    max-width: 100%;
    margin-top: 1rem;
  }
  .genz__contacts-intro p {
    font-size: 16px;
    line-height: 110%;
  }
  .genz__contacts-intro h2 {
    font-size: 32px;
    line-height: 110%;
    padding-bottom: 0;
  }
  .genz__contacts-form p {
    font-size: 14px;
    line-height: 110%;
  }
  .genz__contacts-form form {
    margin-top: 0;
  }
  .genz__contacts-form_button {
    margin-bottom: 2rem;
  }
  .genz__contacts-form_button button {
    padding: 1rem 2.5rem;
  }
}
.para {
  color: #ffe700;
}
