.genz__work {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
}
.genz__work-containers1_content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: linear-gradient(
    185deg,
    rgba(30, 29, 30, 1) 0%,
    rgba(21, 21, 21, 1) 100%
  );
  margin: 1rem 1rem;
  border-radius: 35px;
  width: 406px;
  height: 300px;
  box-shadow: rgba(17, 18, 19, 0.4) 0px 0px 0px 2px,
    rgba(24, 24, 24, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.genz__work-containers_content__text h3 {
  padding-top: 2rem;
  font-family: var(--font-family);
  color: var(--color-text);
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.02em;
}
.genz__work-containers_content__text p {
  padding-top: 1rem;
  font-family: var(--font-body);
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.01em;
}
.genz__work-containers1_content__image {
  /* height: 50%; */
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.genz__work-containers1_content__image img {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 650px) {
  .genz__work-containers1_content {
    width: 100%;
    height: 350px;
  }
}
