.genz__about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.genz__about p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.genz__about h2 {
  padding-top: 2rem;
  width: 60%;
  font-family: var(--font-family);
  color: var(--color-textbody);
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
}
.genz__about span {
  color: var(--color-text);
}
@media screen and (max-width: 1400px) {
  .genz__about h2 {
    font-size: 38px;
    /* width: 90%; */
  }
}
@media screen and (max-width: 650px) {
  .genz__about h2 {
    font-size: 32px;
    line-height: 110%;
    width: 100%;
  }
}
