.genz__cta {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.genz__cta-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  max-width: 70%;
  margin: 0 auto;
}
.genz__cta-content_text {
  padding-right: 2rem;
  width: 50%;
}
.genz__cta-content_text p {
  color: var(--color-subtext); 
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  padding-bottom: 2rem;
}
.genz__cta-content_text h2 {
  color: var(--color-text);
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.03em;
}

.genz__cta-content_image {
  align-items: center;
  max-width: 50%;
}
.genz__cta-content_image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: rgba(26, 26, 26, 0.2) 0px 12px 28px 0px,
    rgba(82, 82, 82, 0.1) 0px 2px 4px 0px,
    rgba(130, 130, 130, 0.05) 0px 0px 0px 1px inset;
}
@media screen and (max-width: 1100px) {
  .genz__cta-content_text h2 {
    padding-top: 2rem;
    font-size: 32px;
    line-height: 110%;
  }
}
@media screen and (max-width: 650px) {
  .genz__cta-content_text {
    padding-right: 0;
  }
  .genz__cta-content_text h2 {
    padding-top: 1rem;
    font-size: 32px;
    line-height: 110%;
    width: 100%;
  }
  .genz__cta-content_text {
    width: 100%;
  }
  .genz__cta-content_image {
    padding-top: 2rem;
    align-items: center;
    max-width: 100%;
  }
  .genz__cta-content {
    flex-direction: column;
    margin: auto;
    max-width: 100%;
    text-align: center;
  }
}
