* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clop: text;
  -webkit-text-fill-color: transparent;
}
.gradient__text1 {
  background: var(--gradient-text1);
  background-clip: text;
  -webkit-background-clop: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 5rem 1.5rem;
}
.section__margin {
  margin: 5rem 1.5rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 3rem 1.5rem;
  }
  .section__margin {
    margin: 3rem 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 3rem 1.5rem;
  }
  .section__margin {
    margin: 3rem 1.5rem;
  }
}
