.logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.genz__client {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.genz__client p {
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--color-textbody);
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.logos {
  overflow: hidden;
  padding: auto 0;
  max-width: 80%;
  /* background: white; */
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  max-width: 80%;
  height: 100%;
  content: "";
  z-index: 3;
}

.logos:before {
  left: 0;
  /* background: linear-gradient(to left, rgba(44, 43, 42, 0.5), #171717); */
  opacity: 30%;
}

.logos:after {
  right: 0;
  opacity: 30%;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0), #171717); */
}

.logos-slide {
  display: inline-block;
  animation: 25s slide infinite linear;
}

.logos-slide img {
  max-height: 55px;
  max-width: 250px;
  margin: 0 3rem;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
